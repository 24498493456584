export const LayoutActionTypes = {
  CHANGE_LAYOUT: "@@layout/CHANGE_LAYOUT",
  CHANGE_LAYOUT_WIDTH: "@@layout/CHANGE_LAYOUT_WIDTH",
  CHANGE_SIDEBAR_THEME: "@@layout/CHANGE_SIDEBAR_THEME",
  CHANGE_SIDEBAR_TYPE: "@@layout/CHANGE_SIDEBAR_TYPE",

  TOGGLE_RIGHT_SIDEBAR: "@@layout/TOGGLE_RIGHT_SIDEBAR",
  SHOW_RIGHT_SIDEBAR: "@@layout/SHOW_RIGHT_SIDEBAR",
  HIDE_RIGHT_SIDEBAR: "@@layout/HIDE_RIGHT_SIDEBAR",
};
